import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import RaisedButton from 'material-ui/RaisedButton';

import Login from './Login';
import Dashboard from './Dashboard';
const style = {
  margin: 15,
};

class Loginscreen extends Component {
  constructor(props){
    super(props);
    var loginButtons=[];
    this.state={
      username:'',
      password:'',
      loginscreen:[],
      loginButtons:loginButtons,
      studentbuttonLabel:'Register as Student',
      teacherbuttonLabel:'Register as Teacher',
      isLogin:true
    }
  }
  componentDidMount(){
    var loginscreen=[];
    //The Login is being displayed as a result of the following line.
    loginscreen.push(<Login parentContext={this} appContext={this.props.appContext}/>);
    this.setState({
                  loginscreen:loginscreen,
                    })
  }
  handleClick(event,userRole){
    if(this.state.isLogin){
      let loginscreen=[];
      let loginButtons=[];
      loginButtons.push(
        <div key="login-button">
        <MuiThemeProvider>
          <div>
             <RaisedButton label={"Login"} primary={true} style={style} onClick={(event) => this.handleClick(event,userRole)}/>
         </div>
         </MuiThemeProvider>
        </div>
      )
      this.setState({
                     loginscreen:loginscreen,
                     loginButtons:loginButtons,
                     isLogin:false
                   })
    }
    else{
      let loginscreen=[],loginButtons=[];
      loginscreen.push(<Login parentContext={this} appContext={this.props.appContext} role={userRole}/>);
      this.setState({
                     loginscreen:loginscreen,
                     loginButtons:loginButtons,
                     isLogin:true
                   })
    }
  }
  render() {
    return (
      <div className="loginscreen" key="loginscreen">
        {this.state.loginscreen}
        <div>
          {this.state.loginButtons}
        </div>
      </div>
    );
  }
}


export default Loginscreen;