import React from 'react';
import { Link } from 'react-router-dom';

const Tickets = () => (
    <div>
    <section style = {{ height: '64px', }}>
    </section>
    <h1>Add tickets stuff here.</h1>
    </div>
)
export default Tickets