import React, { Component } from 'react';
//import injectTapEventPlugin from 'react-tap-event-plugin';
import './App.css';
import LoginScreen from './Loginscreen';
import Render from './render';
import MiniDrawer from './TheAppBar';
import Login from './Login';
// Needed for onTouchTap
// http://stackoverflow.com/a/34015469/988941
//injectTapEventPlugin();


class App extends Component {
  constructor(props){
    super(props);
    this.state={
      loginPage:[],
      uploadScreen:[]
    }
  }
  componentDidMount(){

  }
  render() {
    return (
      <div className="App">
      <MiniDrawer></MiniDrawer>
        <Render></Render>
      </div>
    );
  }
}

export default App;