import React from 'react';
import Login from './Login';
import Dashboard from './Dashboard';
import Users from './Users';
import Databases from './Databases';
import Licensing from './Licensing';
import Reports from './Reports';
import Tickets from './Tickets';
import Help from './Help';
import { Switch, Route } from 'react-router-dom';

const Render = () => (
    <main>
    <Switch>
        <Route path='/login' component = {Login}/>
        <Route path='/dashboard' component = {Dashboard}/>
        <Route path='/users' component = {Users}/>
        <Route path='/databases' component = {Databases}/>
        <Route path='/licensing' component = {Licensing}/>
        <Route path='/reports' component = {Reports}/>
        <Route path='/tickets' component = {Tickets}/>
        <Route path='/help' component = {Help}/>
    </Switch>
    </main>
)

export default Render